import React from 'react';
import {AyoLogo} from "../common/AyoLogo";
import {TypeAnimation} from "react-type-animation";
import {SocialButton} from "../common/SocialButton";
import {brands, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import './email_confirmation.css';

export function EmailConfirmation() {
    return <div className="email_confirmation">
        <AyoLogo></AyoLogo>
        <TypeAnimation
            sequence={[
                'Your email has successfully been verified!',
                1000,
                () => {}
            ]}
            wrapper="div"
            cursor={false}
            className="typing_text"
            repeat={0}
        />
        <FontAwesomeIcon icon={solid('check')} color="wh" size="2x" className="check-icon"/>
        <div className="social_icon_container">
            <SocialButton icon={brands('instagram')} link="https://www.instagram.com/ayomessage/"></SocialButton>
            <SocialButton icon={brands('twitter')} link="https://twitter.com/ayomessage"></SocialButton>
            <SocialButton icon={brands('tiktok')} link="https://www.tiktok.com/@ayomessage"></SocialButton>
        </div>
    </div>
}