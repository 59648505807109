import React, { useState } from 'react';
import './reset_password.css';
import { AyoLogo } from "../common/AyoLogo";
import { supabase } from "../client/supabaseClient";
import { TypeAnimation } from "react-type-animation";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export function ResetPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        const { error } = await supabase.auth.updateUser({ password: newPassword });

        if (error) {
            setHasError(true);
        } else {
            setIsSuccess(true);
        }
    };

    return (
        <div className="reset-password">
            <AyoLogo></AyoLogo>
            {isSuccess
                ? <div>
                    <TypeAnimation
                        sequence={[
                            'Your password has successfully been reset!',
                            1000,
                            () => { }
                        ]}
                        wrapper="div"
                        cursor={false}
                        className="typing_text"
                        repeat={0}
                    />
                    <FontAwesomeIcon icon={solid('check')} color="wh" size="2x" className="check-icon" />
                </div>
                : <div className='form'>
                    <h1>Reset your password</h1>
                    <form onSubmit={handlePasswordReset}>
                        <div className='row-form'>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                            <span className='hide-span' onClick={() => setShowPassword(!showPassword)}>
                                <FontAwesomeIcon icon={showPassword ? solid('eye') : solid('eye-slash')} color="wh" size="1x" className='hide-btn' />
                            </span>
                        </div>
                        <button className='submit-btn' type="submit">Reset Password</button>
                    </form>
                    {hasError && <span className='error'>An error has occured, try again</span>}
                </div>
            }
        </div>
    );
}