import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react';

export function StoreButton({text, icon, link}) {
    return <div className="store_button" onClick={()=> window.open(link, "_blank")}>
        <div className="store_icon_container">
            <FontAwesomeIcon className="store_icon" icon={icon} size="2x"/>
        </div>
        <div>
            <span className="download_text">Download on the</span>
            <span className="store_name">{text}</span>
        </div>
    </div>
}