import React, { useState } from 'react';
import {supabase} from "../client/supabaseClient";

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) =>{
    event.preventDefault();

    let response = await supabase.auth.signInWithPassword({
        email: username,
        password: password,
      });

      if (response.error) {return }

      console.log(response.data.user.id);

      let responseDelete =  await supabase.rpc('delete_account', { user_id_input: response.data.user.id })
      console.log(responseDelete);

    setUsername('');
    setPassword('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="username">Nom d'utilisateur:</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={handleUsernameChange}
          required
        />
      </div>
      <div>
        <label htmlFor="password">Mot de passe:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={handlePasswordChange}
          required
        />
      </div>
      <button type="submit">Supprimer son compte</button>
    </form>
  );
};

export default LoginForm;