import {createClient} from '@supabase/supabase-js'

//const supabaseUrl = env.REACT_APP_SUPABASE_URL;
//const supabaseAnonKey = env.REACT_APP_SUPABASE_ANON_KEY;
const supabaseUrl = "https://yowculgrpdifldypoeht.supabase.co";
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inlvd2N1bGdycGRpZmxkeXBvZWh0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDUwNDM2MDEsImV4cCI6MTk2MDYxOTYwMX0.76r02pcHANAoH_x64Nq2_IEq4tnkxgwzTLXLOBqpHEQ";


const options = {
    db: {
        schema: 'public',
    },
    auth: {
        detectSessionInUrl: true
    }
}


export const supabase = createClient(supabaseUrl, supabaseAnonKey, options)