import React from 'react';
import {AyoLogo} from "../common/AyoLogo";
import {SocialButton} from "../common/SocialButton";
import {brands} from "@fortawesome/fontawesome-svg-core/import.macro";
import './delete_account.css';
import LoginForm from './login_form';

export function DeleteAccount() {
    return <div className="delete_account">
        <AyoLogo></AyoLogo>
        <LoginForm></LoginForm>
        <div className="social_icon_container">
            <SocialButton icon={brands('instagram')} link="https://www.instagram.com/ayomessage/"></SocialButton>
            <SocialButton icon={brands('twitter')} link="https://twitter.com/ayomessage"></SocialButton>
            <SocialButton icon={brands('tiktok')} link="https://www.tiktok.com/@ayomessage"></SocialButton>
        </div>
    </div>
}