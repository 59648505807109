import './App.css';
import {Home} from "./home/Home";
import {EmailConfirmation} from "./email_confirmation/EmailConfirmation";
import {ResetPassword} from "./reset_password/ResetPassword";
import { Routes, Route } from "react-router-dom";
import { DeleteAccount } from './delete_account/DeleteAccount';

function App() {
return (
   <Routes>
        <Route path="/" element={<Home />}/>
        <Route index element={<Home />} />
        <Route path="delete-account" element={<DeleteAccount />} />
        <Route path="email-confirmation" element={<EmailConfirmation />} />
        <Route path="reset-password" element={<ResetPassword />} />
    </Routes>
  );
}

export default App;
