import React from 'react';
import {AyoLogo} from "../common/AyoLogo";
import './home.css';
import {StoreButton} from "../common/StoreButton";
import {brands} from "@fortawesome/fontawesome-svg-core/import.macro";
import {TypeAnimation} from "react-type-animation";
import {SocialButton} from "../common/SocialButton";

export function Home() {
    return <div className="home">
        <AyoLogo></AyoLogo>
        <TypeAnimation
            sequence={[
                'The smart way of messaging',
                1000,
            ]}
            wrapper="div"
            cursor={false}
            className="typing_text"
            repeat={0}
        />
        <div className="store_button_container">
            <StoreButton text="Google Play" icon={brands('google-play')} link="https://play.google.com/store/apps/details?id=com.ayo.app&pli=1"></StoreButton>
            <StoreButton text="App Store" icon={brands('apple')} link="https://apps.apple.com/app/ayo-smart-way-of-messaging/id1634626905"></StoreButton>
        </div>
        <div className="social_icon_container">
            <SocialButton icon={brands('instagram')} link="https://www.instagram.com/ayomessage/"></SocialButton>
            <SocialButton icon={brands('twitter')} link="https://twitter.com/ayomessage"></SocialButton>
            <SocialButton icon={brands('tiktok')} link="https://www.tiktok.com/@ayomessage"></SocialButton>
        </div>
    </div>

}